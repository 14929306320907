<template>
  <v-footer id="home-footer" color="black" min-height="72">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <div
            class="d-flex flex-wrap justify-md-start justify-center justify-md-none"
          >
            <template v-for="(s, i) in social">
              <a
                target="_blank"
                :key="i"
                class="white--text pa-1 pa-md-0"
                :href="s.uri"
                v-text="s.name"
              />

              <v-responsive
                v-if="i < social.length - 1"
                :key="`divider-${s.name}`"
                class="mx-4 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>
            </template>
          </div>
        </v-col>

        <v-col class="text-center text-md-right" cols="12" md="6">
          Copyright &copy; {{ new Date().getFullYear() }} Crypto-mation
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "HomeFooter",

  data: () => ({
    social: [
      { name: "Facebook", uri: "https://www.facebook.com/CryptoMationTH/" },
      { name: "Twitter", uri: "https://twitter.com/crypto_mation" },
      { name: "Youtube", uri: "https://www.youtube.com/channel/UCUE5l-SC-LYoqxgHHLjDEAA" },
    ],
  }),
};
</script>

<style lang="sass">
#home-footer a
  text-decoration: none
</style>
