<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      elevation="1"
      elevate-on-scroll
      height="60"
      color="secondary"
    >
      <a @click="$vuetify.goTo('#hero')"
        ><Img
          :src="require('@/assets/app-bar-logo.png')"
          class="mr-3 hidden-xs-only"
          contain
          max-width="52"
          width="100%"
          @click="$vuetify.goTo('#hero')"
      /></a>

      <v-toolbar-title class="headline">
        <a @click="$vuetify.goTo('#hero')">
          <h4 class="white--text">CRYPTO-MATION</h4>
        </a>
      </v-toolbar-title>

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
          background-color="transparent"
          active-class="blue-grey lighten-5"
          color="blue"
        >
          <v-tab
            v-for="(name, i) in items"
            :key="i"
            v-on:click="$vuetify.goTo(nameSectionItem[i])"
            :ripple="false"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ name }}
          </v-tab>
          <v-tab
            key="gotoRegister"
            to="/signin"
            class="font-weight-bold"
            min-width="96"
            text
            exact
            :ripple="false"
          >
            <v-btn color="green"> สมัครสมาชิก / เข้าสู่ระบบ </v-btn>
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />
    </v-app-bar>
    <CommercialDrawer
      v-model="drawer"
      :items="items"
      :nameItem="nameSectionItem"
    />
  </div>
</template>

<script>
import Img from "./subcomponents/Img.vue";
import CommercialDrawer from "./CommercialDrawer.vue";

export default {
  name: "CommercialAppBar",
  data: () => ({
    drawer: false,
    group: null,
    items: [
      "ความสามารถ",
      "โรบอทเทรด",
      "ดาวน์โหลด",
      "ใช้งานอย่างไร",
      "ค่าบริการ",
      "เกี่ยวกับเรา",
    ],
    nameSectionItem: [
      "#features",
      "#project",
      "#howto",
      "#docs",
      "#pricing",
      "#about",
    ],
  }),
  components: { Img, CommercialDrawer },
};
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

  .v-tab
    &::before
      display: none
</style>
